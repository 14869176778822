<template>
  <section>
    <ui-list
      :heading="heading"
      :headings="[$t('fields.name'), $tc('models.role', 1), $tc('models.permission'), 'search']"
      :items="users"
      :sort="[
        { prop: 'firstName', type: false },
        { prop: 'role', prop2: 'name', type: 'level2' }
      ]"
      :search_selectors="['firstName', 'lastName', 'email']"
    >
      <template v-slot:default="props">
        <ui-list-data
          :text="
            (props.item.firstName && props.item.lastName) ? `${props.item.firstName} ${props.item.lastName}` : $t('misc.not-registered')"
          :info="
            props.item.email"
        >
        </ui-list-data>

        <ui-list-data
          type="tag"
          :text="`${$t('roles.' + props.item.role.name)}`"
        />

        <!-- List of user permissions within that company with department scope -->
        <div class="flex-container">
          <ui-list-data class="flex-item" :key="perm.id" v-for="perm in props.item.permissions" type="tag" :text="perm.scope + ':' + perm.permission"/>
          <ui-list-data v-if="props.item.permissions.length == 0" type="tag" :text="''"/>
        </div>

        <ui-list-data>
          <!-- workaroud as justify-end for ui-link -->
          <div style="width: 100%;"></div>
          <ui-link
            type="danger"
            size="small"
            uppercase
            @click="showDeleteModal(true, props.item)"
            >{{ $t('actions.remove') }}
          </ui-link>   
          <!-- <ui-link type="danger" size="small" uppercase @click="removeUser(props.item.id)">{{ $t('actions.remove') }}</ui-link> -->
        </ui-list-data>

      </template>
    </ui-list>

    <ui-modal
      :show="showDelete"
      :text="
        $t('actions.confirm_delete', {
          model: $tc('models.user').toLowerCase()
        })
      "
      :info="$t('info.delete_department_user')"
      :confirmText="$t('actions.delete')"
      @confirm="removeUser()"
      @cancel="showDeleteModal(false)"
    >
    </ui-modal>
  </section>
</template>

<script>
import UiLink from '@/components/ui/UiLink';
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';
import UiModal from '@/components/ui/UiModal';

export default {
  props: {
    heading: {
      type: String
    },
    users: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      markedDelete: null,
      showDelete: false
    };
  },

  components: {
    UiLink,
    UiList,
    UiListData,
    UiModal
  },

  methods: {
    removeUser() {
      this.$emit('removeUser', this.markedDelete.id);
      this.showDeleteModal(false);
    },

    showDeleteModal(show, item) {
      this.showDelete = show;
      if (show) {
        this.markedDelete = item;
      } else {
        this.markedDelete = null;
      }
    }
  }
};
</script>

<style scoped>
  .flex-container {
      display: flex;
      flex-direction:column;
  }

  .flex-item:not(:first-child) {
    border-top-width: 0
  }
</style>
